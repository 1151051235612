<script setup lang="ts">
import { useWindowScroll } from "@vueuse/core";
import IconArrow from "~/assets/icons/arrow.svg";
import LazyUIButton from "@repo/ui/src/components/ui/button/Button.vue"

const { y } = useWindowScroll({ behavior: "smooth" });
const visibleButton = computed(() => y.value >= 100);
</script>
<template>
  <LazyUIButton
    :class="{
      'animate-in fade-in': visibleButton,
      hidden: !visibleButton,
    }"
    class="rounded-full w-12 h-12"
    variant="outline"
    size="icon"
    @click="y = 0"
    :style="{ zIndex: 5 }"
  >
    <IconArrow class="text-2xl" />
  </LazyUIButton>
</template>
